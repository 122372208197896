<template>
  <div class="five-face">
    <breadcrumb
      :title="$route.query.line || '设备对接'"
      childTitle="六面钻"
      @goBackPage="goBackPage"
    >
      <div>
        <!-- <a-button><a-icon type="form" />导入配置</a-button>
        <a-button><a-icon type="share-alt" />分享配置</a-button> -->
        <a-button @click.native="saveSixFace"
          ><a-icon type="save" />{{ $t('common.save') }}</a-button
        >
      </div>
    </breadcrumb>
    <div class="wrap">
      <div class="file-format-box">
        <span class="head-title">{{ $t('dock56Drill.fileType') }}</span>
        <div class="file-format">
          <span>{{ $t('dock56Drill.fileType') }}：</span>
          <a-select
            default-value="xml"
            v-model="format56"
            @change="showBanSetting"
            :dropdownMenuStyle="{ 'max-height': '280px' }"
            :dropdownStyle="{ height: '280px' }"
            showArrow
          >
            <a-select-option
              :value="item.value"
              v-for="(item, index) in selectList"
              :key="index"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </div>
      </div>
      <div class="mpr-file-format" v-if="format56 == 'mpr'">
        <span class="head-title">{{ $t('dock56Drill.outputMprFile') }}</span>
        <div class="mpr-file-radio">
          <span>{{ $t('dock56Drill.outputMprFile') }}</span>
          <a-radio-group v-model="dock6FOutputMprFile">
            <a-radio value="mpr">{{ $t('dock56Drill.oldMPR') }}</a-radio>
            <a-radio value="newMpr">{{ $t('dock56Drill.newMPR') }}</a-radio>
          </a-radio-group>
        </div>
      </div>
      <div class="through-hole-box">
        <span class="title">{{ $t('dock56Drill.throughHoleSetting') }}</span>
        <div class="through-hole">
          <span>{{ $t('dock56Drill.throughHoleSetting') }}</span>
          <a-checkbox v-model="dock6FDrill_towSideConHole"></a-checkbox>
        </div>
      </div>
      <div class="code-box" v-if="isShowBanSetting">
        <span class="title">{{ $t('dock56Drill.banFileSetting') }}</span>
        <div class="code">
          <span>{{ $t('dock56Drill.banFileCodeInfo') }}</span>
          <a-checkbox v-model="dock6FDrill_code"></a-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import breadcrumb from '@/components/topBreadCrumb.vue'

export default {
  components: {
    breadcrumb,
  },
  data() {
    return {
      selectList: [
        { name: 'BAN', value: 'ban' },
        { name: 'BAN_SC', value: 'bansc' },
        { name: 'BAN2', value: 'ban2' },
        { name: 'BAN3', value: 'ban3' },
        { name: 'BAN4', value: 'ban4' },
        { name: 'XML1', value: 'xml1' },
        { name: 'XML3', value: 'xml3' },
        { name: 'MPR', value: 'mpr' },
      ],
      format56: 'ban',
      // 记录新旧mpr格式
      dock6FOutputMprFile: 'mpr',
      // 是否为双面打通
      dock6FDrill_towSideConHole: false,
      // ban格式是否包含code信息
      dock6FDrill_code: false,
      isShowBanSetting: true,
    }
  },
  computed: {
    previewInfo() {
      return this.$store.state.previewInfo
    },
  },
  methods: {
    // 回到设备对接页面
    goBackPage() {
      let path = '/equipment'
      if (this.previewInfo.isPreview) {
        path = `${path}-preview`
      }

      this.$router.push(path)
    },
    // 只有切换到ban格式相关的才会显示ban格式设置
    showBanSetting() {
      if (this.format56.includes('ban')) {
        this.isShowBanSetting = true
      } else {
        this.dock6FDrill_code = false
        this.isShowBanSetting = false
        this.dock6FDrill_code = false
      }
      if (this.format56 != 'mpr') {
        this.dock6FOutputMprFile = 'mpr'
      }
    },
    // 保存六面钻信息
    saveSixFace() {
      let obj = {
        data: {
          format56: this.format56,
          dock6FDrill_code: this.dock6FDrill_code,
          dock6FDrill_towSideConHole: this.dock6FDrill_towSideConHole,
          dock6FOutputMprFile: this.dock6FOutputMprFile,
        },
        setting_id: this.$route.query.setting_id,
      }
      this.$token('/save_dock6F_setting', JSON.stringify(obj), (res) => {
        if (res.status == 1) {
          this.$message({
            message: this.$t('common.saveSuccess'),
            type: 'success',
            duration: 1500,
            offset: 70,
          })
        } else {
          this.$message({
            message: this.$t('lineManager.permissionTip'),
            type: 'info',
            duration: 1500,
            offset: 70,
          })
        }
      })
    },
    // 加载六面钻信息
    loadSixFace() {
      let obj = {
        setting_id: this.$route.query.setting_id,
      }
      this.$token('/load_dock6F_setting', obj, (res) => {
        if (res.status == 1) {
          if (this.format56 == null) {
            this.format56 = 'ban'
          } else {
            let flag = true
            this.selectList.forEach((v) => {
              if (v.value == res.data.format56) {
                flag = false
              }
            })
            if (flag) {
              this.format56 = ''
            } else {
              this.format56 = res.data.format56
            }
          }
          this.dock6FOutputMprFile = res.data.dock6FOutputMprFile
          this.dock6FDrill_towSideConHole = res.data.dock6FDrill_towSideConHole
          this.dock6FDrill_code = res.data.dock6FDrill_code
          this.showBanSetting()
        } else {
          this.$message({
            message: this.$t('lineManager.permissionTip'),
            type: 'info',
            duration: 1500,
            offset: 70,
          })
        }
      })
    },
  },
  mounted() {
    this.loadSixFace()
  },
}
</script>

<style scoped lang="less">
.five-face {
  width: 100%;
  height: calc(100vh - 60px);
  .wrap {
    max-width: 1392px;
    margin: 0 auto;
    background: #fff;
    margin-top: 24px;
    .title {
      width: 100%;
      padding: 24px;
      box-sizing: border-box;
      display: inline-block;
      font-size: 18px;
      color: #000;
      font-weight: 600;
      border-bottom: 1px solid #e9e9e9;
    }
    .file-format-box {
      .file-format {
        padding: 24px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        .ant-select {
          width: 145px;
        }
      }
    }
    .mpr-file-format {
      .mpr-file-radio {
        padding: 24px;
      }
    }
    .through-hole-box {
      .through-hole {
        display: flex;
        align-items: center;
        padding: 24px;
        box-sizing: border-box;
      }
    }
    .code-box {
      .code {
        display: flex;
        align-items: center;
        padding: 24px;
        box-sizing: border-box;
      }
    }
  }
}
</style>
